<template>
  <div class="serviceMain Withdrawal mineForm"> 
     
            <div class="topBar"> 
                <div class="leftInfo"> 
                   提现
                </div>
                <div class="rightMain"> 
                     <a @click="back"> 返回上一级 </a>
                </div>
            </div>
           
             <div class="formWrap">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="180px"
          >
            <el-form-item
              label="提现收款银行账户"
              prop="name"
            >
              <el-input v-model="form.name" placeholder="请输入收款银行账户"></el-input>
            </el-form-item>
             <el-form-item
              label="提现银行"
              prop="bank"
            >
              <el-input v-model="form.bank" placeholder="请输入提现银行"></el-input>
            </el-form-item>
             <el-form-item
              label="收款人"
              prop="person"
            >
              <el-input v-model="form.person" placeholder="请输入收款人"></el-input>
            </el-form-item>
             <el-form-item
              label="提现金额"
              prop="count"
            >
              <el-input v-model="form.count" placeholder="请输入提现金额"></el-input>
            </el-form-item>
            
                  <el-form-item prop="pwd" label="密码">
                  <el-input v-model="form.loginPwd" type="password" placeholder="请输入密码">
                  </el-input>
                </el-form-item>
                 <el-form-item prop="verifyCode" label="短信验证码">
                  <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <span slot="suffix" class="yzm"> 发送短信验证码 </span>
                  </el-input>
                </el-form-item>
                   <el-form-item class="submitRow">
              <el-button
                type="primary"
                @click="onSubmit"
              >确认添加</el-button>
            </el-form-item>
          </el-form>
             </div>
      
  </div>
</template>

<script>
export default {
  name: 'Withdrawal',

  data () {
    return {
       form: {
        name: '',
        bank:'',
        person:'',
        count:'',
        pwd:'',
        verifyCode:'',
        loginPwd:''
      },
      rules: {
        
      },
    }
  },

  methods: {
      back(){
          this.$emit('set-tab-idx','tab1')
      },
    
  }
}
</script>

<style lang='scss' scoped>
@import '../../../../assets/css/mine.scss';
.Withdrawal{
  padding-top:0 !important;
    .topBar{
      padding:24px 0;
      .leftInfo{
        color:#000;
        font-size:16px;
        font-weight: bolder;
      }
    }
    .yzm{
      cursor:pointer;
      color:#007DFF;
    }
    
}

   
</style>
