<template>
  <div>      
  <div  v-if="tabIdx=='tab1'">   
      <div class="topBar"> 
          <div class="leftInfo"> 
              账户余额：
              <span class="price"> {{balance}} </span>
          </div>
          <div class="rightMain"> 
                <el-button type="text" size="small"  @click="seeWithdrawList">提现记录</el-button>
                <el-button type="primary" size="small" @click="Withdrawal">提现</el-button>
          </div>
      </div>
      <div class="serviceMain">
      <div class="searchBar">
        <div class="tittle"> 账单明细 </div>
        <div class="rightForm">
          <el-input
            v-model="form.name"
            placeholder="请输入作品名称"
          ></el-input>
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-button>查询</el-button>
        </div>
      </div>

      <el-table
        :data="tableData"
        width="100%"
        @row-click="onTabelRowClick"
      >
        <el-table-column
          prop="name"
          label="说明"
           show-overflow-tooltip
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="180"
        >
        </el-table-column>
         <el-table-column
          prop="price"
          label="交易金额"
          width="120"
        >
         <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
              <div 
              :class="{'addPrice':scope.row.type==1,'cutPrice':scope.row.type==2}"> 
               {{scope.row.type==1?'+':'-'}}{{scope.row.price}}
               </div>
              <div class="smallCountCell">  余额 {{scope.row.lastMoney}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="创建时间"
          sortable
          width="180"
        >
          <!-- eslint-disable-next-line -->
          <template slot="header" slot-scope="scope">
            <div class="createTimeHeader">
              <span>创建时间</span>
              <a-tooltip>
                <template slot="title">
                  prompt text
                </template>
                <a-icon type="question-circle" />
              </a-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="100"
        >
          <template slot-scope="scope">
            <span
              class="stausCell"
              :class="{'stausCell1':scope.row.status==1,'stausCell2':scope.row.status==2,'stausCell3':scope.row.status==3}"
            >
              {{scope.row.statusDesc}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
          fixed="right"
        >
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <!-- {{scope.row.status}} -->
        
                <a-dropdown
              :trigger="['click']"
              placement="bottomLeft"
              overlayClassName="moveLeft"
            >
              <a @click.stop="e => e.preventDefault()">
                <a-icon type="more" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                <router-link :to="{path:'/Monitor/Detail'}"> 查看监测 </router-link>  
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <a-icon type="right" class="ml12 gray" />
        
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1000"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
      </div>
  </div>    
    <div v-if="tabIdx=='tab2'">
      <withdrawal-list
      @set-tab-idx="setTabIdx"></withdrawal-list>
    </div>
     <div v-if="tabIdx=='tab3'">
      <withdrawal
      @set-tab-idx="setTabIdx"></withdrawal>
    </div>
  </div>
</template>

<script>
import WithdrawalList from './components/WithdrawalList'
import Withdrawal from './components/Withdrawal'
import { mapGetters } from "vuex";
import {getUserBalance} from '@/api/ObtainEvidence'
export default {
  name: 'MoneyBag',
  components: {WithdrawalList,Withdrawal },
  data () {
    return {
      balance: 0,
      tabIdx: 'tab1',
      form: {
        name: '',
        date: ''
      },
      pagination: {
        page: 1,
        pageSize: 25
      },
      tableData: [{
        name: '挂卖：《三体》的读后感 ',
        orderNo: '109000990099998',
        type:1, // 1 入  2 出
        price:'100',
        lastMoney:'99',
        date: '2019-12-23 14:07',
        status: 1, // 1、交易失败 2、交易中  3、交易成功
        statusDesc: "交易失败",
      },{
        name: '挂卖：《三体》的读后感 ',
        orderNo: '109000990099998',
        type:2, // 1 入  2 出
        price:'10',
        lastMoney:'99',
        date: '2019-12-23 14:07',
        status: 2, // 1、交易失败 2、交易中  3、交易成功
        statusDesc: "交易中",
      },{
        name: '挂卖：《三体》的读后感 ',
        orderNo: '109000990099998',
        type:1, // 1 入  2 出
        price:'10',
        lastMoney:'99',
        date: '2019-12-23 14:07',
        status: 3, // 1、交易失败 2、交易中  3、交易成功
        statusDesc: "交易成功",
      }]
    }
  },
  computed: {
    ...mapGetters(["activeManue", "mid", "userInfo"]),
  },
  mounted(){
    const tempQuery=this.$route.query
    if(!!tempQuery.tabIdx){
      this.tabIdx=tempQuery.tabIdx
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    onTabelRowClick(row){
      this.$router.push({
        path: "/OrderDetail",
        query: {}
      });
    },
    fetchData (param) {
      getUserBalance(this.mid).then(({data})=>{
         console.log("我的余额:",data);
        this.balance = data.cny
      });
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },
      setTabIdx(tabIdx){
          this.tabIdx=tabIdx
      },
      seeWithdrawList(){
           this.setTabIdx('tab2')
      },
      Withdrawal(){
         this.setTabIdx('tab3')
      }
  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
</style>
