<template>
  <div class="serviceMain withDrawalList"> 
            <div class="topBar"> 
                <div class="leftInfo"> 
                   提现记录
                </div>
                <div class="rightMain"> 
                     <a @click="back"> 返回上一级 </a>
                </div>
            </div>
         <!-- <router-link :to="{path:'/WithdrawalDetail'}">   
            <el-button type="text">提现详情</el-button>
         </router-link>  -->
          <el-table
        :data="tableData"
        width="100%"
        @row-click="onTabelRowClick"
      >
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="创建时间"
          sortable
          width="180"
        >
          <!-- eslint-disable-next-line -->
          <template slot="header" slot-scope="scope">
            <div class="createTimeHeader">
              <span>创建时间</span>
              <a-tooltip>
                <template slot="title">
                  prompt text
                </template>
                <a-icon type="question-circle" />
              </a-tooltip>
            </div>
          </template>
        </el-table-column>
         <el-table-column
          prop="price"
          label="提现金额"
          width="120"
        >
          <template slot-scope="scope">
              <div 
              class="cutPrice"> 
             {{scope.row.price}}
               </div>
          </template>
        </el-table-column>
         <el-table-column
          prop="bankCode"
          label="收款账户"
          width="150"
        >
        </el-table-column>
         <el-table-column
          prop="bank"
          label="开户行"
          width="100"
        >
        </el-table-column>
         <el-table-column
          prop="person"
          label="收款人"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="100"
        >
          <template slot-scope="scope">
            <span
              class="stausCell"
              :class="{'stausCell1':scope.row.status==1,'stausCell2':scope.row.status==2,'stausCell3':scope.row.status==3}"
            >
              {{scope.row.statusDesc}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
          fixed="right"
        >
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <!-- {{scope.row.status}} -->
        
                <a-dropdown
              :trigger="['click']"
              placement="bottomLeft"
              overlayClassName="moveLeft"
            >
              <a @click.stop="e => e.preventDefault()">
                <a-icon type="more" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                <router-link :to="{path:'/Monitor/Detail'}"> 查看监测 </router-link>  
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <a-icon type="right" class="ml12 gray" />
        
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1000"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
      </div>
</template>

<script>
export default {
  name: 'WithdrawalList',
  data () {
    return {
         pagination: {
        page: 1,
        pageSize: 25
      },
      tableData: [{
        orderNo: '109000990099998',
        price:'100',
        date: '2019-12-23 14:07',
        status: 1, // 1、失败 2、处理中  3、已完成
        statusDesc: "失败",
        bankCode:'6227****0028',
        bank:'建设银行',
        person:'张安'
      },{
        orderNo: '109000990099998',
        price:'100',
        date: '2019-12-23 14:07',
        status: 2, // 1、失败 2、处理中  3、已完成
        statusDesc: "处理中",
        bankCode:'6227****0028',
        bank:'建设银行',
        person:'张安'
      },{
        orderNo: '109000990099998',
        price:'100',
        date: '2019-12-23 14:07',
        status: 3, // 1、失败 2、处理中  3、已完成
        statusDesc: "已完成",
        bankCode:'6227****0028',
        bank:'建设银行',
        person:'张安'
      }]
    }
  },

  methods: {
      back(){
          this.$emit('set-tab-idx','tab1')
      },
     onTabelRowClick(row){
      this.$router.push({
        path: "/WithdrawalDetail",
        query: {}
      });
    },
     fetchData (param) {
      console.log("fetchData...")
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },
  }
}
</script>

<style lang='scss' scoped>
@import '../../../../assets/css/mine.scss';
.withDrawalList{
  padding-top:0 !important;
    .topBar{
      padding:24px 0;
      .leftInfo{
        color:#000;
        font-size:16px;
        font-weight: bolder;
      }
    }
}
</style>
